<template>
    <Header active="courses" />

    <PageSlider :links="links" />

    <AllCourses :total="total" :courses="courses" :cats="cats" :counter="counter" :currentPage="currentPage" :isFiltered="isFiltered" :currentCategoryId="currentCategoryId" />

    <Footer />
</template>

<script>
    import config from "../../helper/config";

    import Header from "../../components/Header.vue";
    import Footer from "../../components/Footer.vue";
    import PageSlider from "../../components/PageSlider.vue";

    import AllCourses from "./co/AllCourses.vue";

    export default {
        name: "MyCourses",
        components: {
            Header,
            Footer,
            PageSlider,

            AllCourses,
        },
        data() {
            return {
                secure: {
                    username: $cookies.get("username"),
                    token: $cookies.get("token"),
                },
                links: [
                    {
                        name: "Home",
                        href: "/",
                    },
                    {
                        name: "My Courses",
                        href: "",
                    },
                ],
                total: 0,
                courses: [],
                cats: [],
                counter: 9,
                currentPage: 1,
                isFiltered: false,
                currentCategoryId: 0,
            };
        },
        async created() {
            this.total = await config.fetchDataSecure("course/total" + "/user/" + this.secure.username, this.secure.token);
            this.courses = await config.fetchDataSecure("course/page/1/count/" + this.counter + "/user/" + this.secure.username, this.secure.token);
            this.cats = await config.fetchDataSecure("courseCategory" + "/user/" + this.secure.username, this.secure.token);
        },
        methods: {
            async turnPage($page, filtered) {
                if ($page > this.total / this.counter) {
                    return;
                }
                if ($page == 0) {
                    return;
                }
                if (filtered) {
                    this.courses = await config.fetchDataSecure("course/category/" + this.currentCategoryId + "/page/" + $page + "/count/" + this.counter + "/user/" + this.secure.username, this.secure.token);
                    this.currentPage = $page;
                } else {
                    this.courses = await config.fetchDataSecure("course/page/" + $page + "/count/" + this.counter + "/user/" + this.secure.username, this.secure.token);
                    this.currentPage = $page;
                }
            },
            async filterCategory(event) {
                let categoryId = event.target.value;
                if (categoryId == "") {
                    this.isFiltered = false;
                    this.total = await config.fetchDataSecure("course/total" + "/user/" + this.secure.username, this.secure.token);
                    this.courses = await config.fetchDataSecure("course/page/1/count/" + this.counter + "/user/" + this.secure.username, this.secure.token);
                } else {
                    this.isFiltered = true;
                    this.currentCategoryId = categoryId;
                    this.total = await config.fetchDataSecure("course/category/" + categoryId + "/total" + "/user/" + this.secure.username, this.secure.token);
                    this.courses = await config.fetchDataSecure("course/category/" + categoryId + "/page/1/count/" + this.counter + "/user/" + this.secure.username, this.secure.token);
                }
            },
        },
    };
</script>

<style></style>
