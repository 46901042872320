<template>
    <section class="flat-row bg-theme">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <vue-plyr v-if="site_config && site_config.about_us_vid != ''">
                        <video controls playsinline>
                            <source :src="site_config.about_us_vid" />
                        </video>
                    </vue-plyr>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import VuePlyr from "vue-plyr";
    import "vue-plyr/dist/vue-plyr.css";

    export default {
        name: "Whyus",
        components: {
            VuePlyr,
        },
        props: {
            site_config: {
                type: Object,
            },
        },
    };
</script>

<style scoped>
.bg-theme{
    margin: 0px;
    padding: 10px;
}
</style>