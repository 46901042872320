const apiUrl = "https://admin.charisma-vfx.com/api/";
// const apiUrl = "http://127.0.0.1:8000/api/";

export default {
    getApiUrl(){
        return apiUrl;
    },
    async getSiteConfig() {
        let site_config = await this.fetchData("siteConfig")
        site_config = site_config[0]
        return site_config
    },
    async fetchData(url) {
        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
            }
        };
        const response = await fetch(apiUrl + url, requestOptions);
        const data = await response.json();
        return data;
    },
    async fetchDataSecure(url , token) {
        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": token,
            }
        };
        const response = await fetch(apiUrl + url, requestOptions);
        let data = await response.json();
        if(data.Authorization && data.Authorization == 'error'){
            if($cookies.get('username')){
                $cookies.remove('username')
                $cookies.remove('token')
                window.location.href = "/"
            }
            data = [];
        }
        return data;
    },
    async fetchDataSecureNonJson(url , token) {
        const requestOptions = {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": token,
            }
        };
        const response = await fetch(apiUrl + url, requestOptions);
        return response;
    },
    async postData(url , form) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(form)
        };
        const response = await fetch(apiUrl + url, requestOptions);
        const data = await response.json();
        return data;
    },
    async postDataSecure(url , form ,token) {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": token,
            },
            body: JSON.stringify(form)
        };
        const response = await fetch(apiUrl + url, requestOptions);
        const data = await response.json();
        return data;
    },
    logout() {
        $cookies.remove('username')
        $cookies.remove('token')
        window.location.href = "/"
    }
}