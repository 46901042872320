<template>
    <section class="flat-row pd-blog bg-theme blog-zigzac1">
        <div class="container">
            <div class="wrap-post">
                <article class="entry" v-for="work in works" :key="work.id">
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <div class="entry-post">
                                <div class="entry-meta">
                                    <span></span>
                                </div>
                                <h3 class="entry-title">
                                    {{ work.name }}
                                </h3>
                                <div class="entry-author">
                                    <span></span>
                                </div>
                                <div class="entry-content">
                                    <p>
                                        {{ work.description }}
                                    </p>
                                </div>
                                <div class="more-link">
                                    <a :href="work.link">CHECK LINK</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="feature-post">
                                <img :src="work.pic" alt="image" />
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            <div class="text-center" v-if="!waiting && works.length == 0">
                No Worsk Found
            </div>
            <div v-if="waiting">
                <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                </div>
            </div>
            <div class="blog-pagination">
                <ul class="flat-pagination">
                    <li>
                        <a href="#" @click.prevent="turnPage(currentPage - 1)"><i class="fa fa-long-arrow-left"></i></a>
                    </li>
                    <li v-for="index in Math.ceil(total / counter)" :key="index">
                        <a href="#" @click.prevent="turnPage(index)">{{ index }}</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="turnPage(currentPage + 1)"><i class="fa fa-long-arrow-right"></i></a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "AllWorks",
        props: {
            total: {
                type: Number,
            },
            works: {
                type: Array,
            },
            counter: {
                type: Number,
            },
            currentPage: {
                type: Number,
            },
        },
        data() {
            return {
                waiting: true
            }
        },
        watch: {
            works: function (newVal, oldVal) {
                this.waiting = false;
            }
        },
        methods: {
            async turnPage($page) {
                this.$parent.turnPage($page);
            },
        },
    };
</script>

<style scoped>

</style>