<template>
    <section class="flat-row pd-blog bg-theme blog-details">
        <div class="container">
            <div class="wrap-post">
                <div class="row">
                    <div class="col-md-12">
                        <article class="entry">
                            <div class="entry-post">
                                <div class="wrap-comment">
                                    <div id="respond" class="comment-respond">
                                        <h4 class="comment-reply-title">CONTACT US</h4>
                                        <form @submit="send" id="commentform" class="comment-form">
                                            <p class="comment-form-author">
                                                <input type="text" id="name" name="name" v-model="form.name" required="required" placeholder="Name" />
                                                <small v-if="error.name" class="pull-left text-danger">Name field is required</small>
                                            </p>
                                            <p class="comment-form-email">
                                                <input type="text" id="email" name="email" v-model="form.email" required="required" placeholder="Email" />
                                                <small v-if="error.email" class="pull-left text-danger">Email field is required</small>
                                            </p>
                                            <p class="comment-form-comment">
                                                <textarea id="content" name="content" v-model="form.content" required="required" placeholder="Your Message"></textarea>
                                                <small v-if="error.content" class="pull-left text-danger">Message field is required</small>
                                            </p>
                                            <p class="form-submit">
                                                <input type="submit" name="submit" id="submit" class="submit" value="SEND MESSAGE" v-if="sendBtn" />
                                            </p>
                                            <br />
                                            <div v-if="result == 'false'" class="text-center text-danger"><h2>Incorrect Login Information</h2></div>
                                            <div v-if="success" class="text-center text-success"><h2>Message Sent Successfully</h2></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import config from "../../../helper/config";

    export default {
        name: "Contactus",
        data() {
            return {
                form: {
                    name: "",
                    email: "",
                    content: "",
                },
                result: "",
                error: {
                    name: false,
                    email: false,
                    content: false,
                },
                success: false,
                sendBtn: true,
            };
        },
        methods: {
            async send(e) {
                e.preventDefault();
                if (this.form.name == "") {
                    this.error.name = true;
                } else {
                    this.error.name = false;
                }
                if (this.form.email == "") {
                    this.error.email = true;
                } else {
                    this.error.email = false;
                }
                if (this.form.content == "") {
                    this.error.content = true;
                } else {
                    this.error.content = false;
                }
                if (!this.error.name && !this.error.email && !this.error.content) {
                    let res = await config.postData("contactMessage", this.form);
                    this.result = res.result;
                    if (this.result != "false") {
                        this.sendBtn = false;
                        this.success = true;
                    }
                }
            },
        },
    };
</script>

<style scoped>
    .blog-details {
        background: linear-gradient(#ffbf43, #fff);
    }
    .entry {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    #commentform input[type="text"],
    textarea {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding: 10px;
    }
</style>
