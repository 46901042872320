<template>
    <div
        id="rev_slider_1078_1_wrapper"
        class="rev_slider_wrapper fullwidthbanner-container slider_styles space-left space-top"
        data-alias="classic4export"
        data-source="gallery"
        style="margin: 0px auto; background-color: transparent; padding: 0px; margin-top: 0px; margin-bottom: 0px;"
    >
        <div id="rev_slider_1078_1" class="rev_slider fullwidthabanner" style="display: none;" data-version="5.3.0.2">
            <div class="slotholder"></div>
            <ul>
                <li
                    :data-index="'rs-304'+slider.id"
                    data-transition="slideremovedown"
                    data-slotamount="7"
                    data-hideafterloop="0"
                    data-hideslideonmobile="off"
                    data-easein="Power4.easeInOut"
                    data-easeout="Power4.easeInOut"
                    data-masterspeed="2000"
                    data-rotate="0"
                    data-saveperformance="off"
                    data-title="Ken Burns"
                    data-param1=""
                    data-param2=""
                    data-param3=""
                    data-param4=""
                    data-param5=""
                    data-param6=""
                    data-param7=""
                    data-param8=""
                    data-param9=""
                    data-param10=""
                    data-description=""
                    v-for="slider in sliders"
                    :key="slider.id"
                >
                    <img
                        autoplay
                        :src="slider.path"
                        alt=""
                        data-bgposition="center center"
                        data-kenburns="off"
                        data-duration="30000"
                        data-ease="Linear.easeNone"
                        data-scalestart="100"
                        data-scaleend="120"
                        data-rotatestart="0"
                        data-rotateend="0"
                        data-offsetstart="0 0"
                        data-offsetend="0 0"
                        data-bgparallax="10"
                        class="rev-slidebg"
                        data-no-retina
                    />
                    <div
                        class="tp-caption title-slide"
                        :id="'slide-304'+slider.id+'-layer-1'"
                        data-x="['left','left','left','left']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['-77','-77','-77','-77']"
                        data-fontsize="['72','72','45','35']"
                        data-lineheight="['60','60','45','35']"
                        data-fontweight="['700','700','700','700']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"y:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[18,18,18,18]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0"
                        data-paddingleft="[0,0,0,0]"
                        style="z-index: 16; white-space: nowrap; text-transform: left;"
                    >
                        <span>{{ slider.title }}</span><br />
                    </div>
                    <div
                        class="tp-caption sub-title"
                        :id="'slide-304'+slider.id+'-layer-4'"
                        data-x="['left','left','left','left']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['55','55','0','0']"
                        data-fontsize="['16',16','14','14']"
                        data-fontweight="['300','300','300','300']"
                        data-width="660"
                        data-height="none"
                        data-lineheight="['16','16','16','16']"
                        data-whitespace="nowrap"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                        data-textAlign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style="z-index: 17; white-space: nowrap; text-transform: left;"
                    >
                        {{ slider.description }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import config from "../../../helper/config";

    export default {
        name: "Slider",
        props: {
            sliders: {
                type: Array,
            },
        },
        mounted() {
            var interv = setInterval(() => {
                if (this.sliders.length > 0) {
                    if (document.getElementById("slide-304" + this.sliders[0].id + "-layer-1")) {
                        var tpj = jQuery;
                        var revapi1078;
                        tpj(document).ready(function () {
                            if (tpj("#rev_slider_1078_1").revolution == undefined) {
                                revslider_showDoubleJqueryError("#rev_slider_1078_1");
                            } else {
                                revapi1078 = tpj("#rev_slider_1078_1")
                                    .show()
                                    .revolution({
                                        sliderType: "standard",
                                        sliderLayout: "fullscreen",
                                        dottedOverlay: "none",
                                        delay: 9000,
                                        navigation: {
                                            keyboardNavigation: "off",
                                            keyboard_direction: "horizontal",
                                            mouseScrollNavigation: "off",
                                            mouseScrollReverse: "default",
                                            onHoverStop: "off",
                                            touch: {
                                                touchenabled: "on",
                                                swipe_threshold: 75,
                                                swipe_min_touches: 1,
                                                swipe_direction: "horizontal",
                                                drag_block_vertical: false,
                                            },
                                            arrows: {
                                                enable: true,
                                                style: "zeus",
                                                tmp: "",
                                                rtl: false,
                                                hide_onleave: false,
                                                hide_onmobile: true,
                                                hide_under: 0,
                                                hide_over: 9999,
                                                hide_delay: 200,
                                                hide_delay_mobile: 1200,
                                                left: {
                                                    container: "slider",
                                                    h_align: "left",
                                                    v_align: "center",
                                                    h_offset: 20,
                                                    v_offset: 0,
                                                },

                                                right: {
                                                    container: "slider",
                                                    h_align: "right",
                                                    v_align: "center",
                                                    h_offset: 20,
                                                    v_offset: 0,
                                                },
                                            },

                                            bullets: {
                                                enable: true,
                                                style: "uranus",
                                                tmp: '<span class="tp-bullet-inner"></span>',
                                                direction: "horizontal",
                                                rtl: false,

                                                container: "slider",
                                                h_align: "center",
                                                v_align: "bottom",
                                                h_offset: 0,
                                                v_offset: 20,
                                                space: 5,

                                                hide_onleave: false,
                                                hide_onmobile: false,
                                                hide_under: 0,
                                                hide_over: 9999,
                                                hide_delay: 200,
                                                hide_delay_mobile: 1200,
                                            },
                                        },
                                        viewPort: {
                                            enable: true,
                                            outof: "pause",
                                            visible_area: "80%",
                                            presize: false,
                                        },
                                        responsiveLevels: [1240, 1024, 778, 480],
                                        visibilityLevels: [1240, 1024, 778, 480],
                                        gridwidth: [1240, 1024, 778, 480],
                                        gridheight: [600, 600, 500, 400],
                                        lazyType: "none",
                                        parallax: {
                                            type: "mouse",
                                            origo: "slidercenter",
                                            speed: 2000,
                                            levels: [2, 3, 4, 5, 6, 7, 12, 16, 10, 50, 46, 47, 48, 49, 50, 55],
                                            type: "mouse",
                                        },
                                        shadow: 0,
                                        spinner: "off",
                                        stopLoop: "off",
                                        stopAfterLoops: -1,
                                        stopAtSlide: -1,
                                        shuffle: "off",
                                        autoHeight: "off",
                                        hideThumbsOnMobile: "off",
                                        hideSliderAtLimit: 0,
                                        hideCaptionAtLimit: 0,
                                        hideAllCaptionAtLilmit: 0,
                                        debugMode: false,
                                        fallbacks: {
                                            simplifyAll: "off",
                                            nextSlideOnWindowFocus: "off",
                                            disableFocusListener: false,
                                        },
                                    });
                            }
                        });
                        clearInterval(interv);
                    }
                }
            }, 100);
        },
    };
</script>

<style scoped></style>
