<template>
    <div class="wrap-header clearfix">
        <div class="top top-style3">
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <ul class="flat-information">
                            <li>{{ timestamp }}</li>
                            <li v-if="site_config"><span>M : </span>{{ site_config.mobile }}</li>
                            <li v-if="site_config"><span>E : </span>{{ site_config.email }}</li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <div class="wrap-flat">
                            <ul class="flat-login-register">
                                <li v-if="logged_in !== '' && !logged_in && site_config && site_config.login_active == 1"><a href="/login">Login</a></li>
                                <li v-if="logged_in !== '' && !logged_in && site_config && site_config.register_active == 1"><a href="/register">Register</a></li>
                                <li v-if="logged_in !== '' && logged_in"><a href="#" @click="logout">Logout</a></li>
                                <li v-if="logged_in !== '' && logged_in">{{ secure.username }}</li>
                            </ul>
                            <ul class="flat-socials">
                                <li v-if="site_config && site_config.sm_link_fb !== ''">
                                    <a :href="site_config.sm_link_fb" target="_blank"><i class="fa fa-facebook" style="color: #4267B2"></i></a>
                                </li>
                                <li v-if="site_config && site_config.sm_link_instagram !== ''">
                                    <a :href="site_config.sm_link_instagram" target="_blank"><i class="fa fa-instagram" style="color: #f09433"></i></a>
                                </li>
                                <li v-if="site_config && site_config.sm_link_youtube !== ''">
                                    <a :href="site_config.sm_link_youtube" target="_blank"><i class="fa fa-youtube-play" style="color: #FF0000"></i></a>
                                </li>
                                <li v-if="site_config && site_config.sm_link_twitter !== ''">
                                    <a :href="site_config.sm_link_twitter" target="_blank"><i class="fa fa-twitter" style="color: #1DA1F2"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <header id="header" class="header header-style3 styleheader">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="logo">
                            <a href="/"><img v-if="site_config" :src="site_config.icon" alt="image" /></a>
                        </div>
                        <div class="flat-search">
                            <ul>
                                <li v-if="logged_in !== '' && logged_in" class="no-arrow" :class="{ 'active' : active === 'mycourses' }">
                                    <a href="/mycourses" title="">MY COURSES</a>
                                </li>
                                <div v-else>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <li class="show-search" :style="logged_in !== '' && logged_in ? '' : 'display:none;'">
                                    <a href="#">
                                        <i class="lnr lnr-alarm"></i>
                                        <span class="badge badge-light" v-if="notifications.length > 0">{{ notifications.length }}</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="btn-menu">
                                <span></span>
                            </div>
                        </div>
                        <div class="wrap-nav">
                            <nav id="mainnav" class="mainnav">
                                <ul class="menu">
                                    <li class="no-arrow" :class="{ 'active' : active === 'home' }"><a href="/" title="">HOME</a></li>
                                    <li v-if="site_config && site_config.about_us_active == 1" class="no-arrow" :class="{ 'active' : active === 'about' }"><a href="/about" title="">ABOUT US</a></li>
                                    <li v-if="site_config && site_config.courses_active == 1" :class="{ 'active' : active === 'courses' }" class="has-mega-menu">
                                        <a href="/courses" title="" class="has-mega">COURSES</a>
                                        <ul class="submenu submenu-style2 mega-menu three-columns">
                                            <li class="submenu-level" v-for="cat in cats" :key="cat.id">
                                                <ul class="submenu2">
                                                    <li class="sub-title">
                                                        <a :href="'/courses?cat='+cat.id+'-'+cat.name">{{ cat.name }}</a>
                                                        <span class="btn-mega"></span>
                                                    </li>
                                                    <li class="mega-menu-sub">
                                                        <ul>
                                                            <li v-for="course in cat.courses" :key="course.id">
                                                                <a :href="'/courses/'+course.id+'-'+course.title">{{ course.title }}</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li v-if="site_config && site_config.partners_active == 1" class="no-arrow" :class="{ 'active' : active === 'partner' }"><a href="/partner" title="">DEMO REEL</a></li>
                                    <li v-if="site_config && site_config.works_active == 1" class="no-arrow" :class="{ 'active' : active === 'work' }"><a href="/work" title="">OUR WORKS</a></li>
                                </ul>
                            </nav>
                            <div class="top-search" style="background-color: white; border-top: 1.5px solid #ffbf43;">
                                <div class="col-12" v-for="notification in notifications" :key="notification.id" style="border-bottom: 1.5px solid #ffbf43; padding: 10px; margin: 10px;">
                                    <a :href="notification.link+'?not='+notification.id">
                                        {{ ( notifications.indexOf(notification) + 1 ) }} : {{ notification.content }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
    import config from "../helper/config";

    export default {
        name: "Header",
        props: {
            active: String,
        },
        data() {
            return {
                site_config: "",
                secure: {
                    username: $cookies.get("username"),
                    token: $cookies.get("token"),
                },
                logged_in: "",
                timestamp: "",
                cats: [],
                notifications: [],
            };
        },
        watch: {
            site_config: function (newVal, oldVal) {
                const favicon = document.getElementById("favicon");
                favicon.href = newVal.ico;
            },
        },
        async created() {
            this.site_config = await config.getSiteConfig();

            if ($cookies.get("username")) {
                await config.fetchDataSecure("course/total/user/" + this.secure.username, this.secure.token);
                this.logged_in = true;
            } else {
                this.logged_in = false;
            }

            setInterval(this.getNow, 1000);
            this.getNotification();
            setInterval(this.getNotification, 3000);

            this.cats = await config.fetchData("courseCategory/CategoryCourses");
        },
        methods: {
            logout() {
                config.logout();
            },
            async getNotification() {
                if (this.logged_in) {
                    this.notifications = await config.fetchDataSecure("notification/user/" + this.secure.username, this.secure.token);
                }
            },
            getNow: function () {
                const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                const today = new Date();
                const date = today.getFullYear() + "-" + monthNames[today.getMonth()] + "-" + days[today.getDay()];
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date + " " + time;
                this.timestamp = dateTime;
            },
        },
    };
</script>

<style scoped>
    #mainnav .no-arrow a:after {
        content: none;
    }
</style>
