<template>
    <div class="wrap-slider">
        <div class="container page-container">
            <div class="page-content">
                <div class="page-title">
                    <div class="breadcrumbs">
                        <ul>
                            <li v-for="link in links" :key="link.name">
                                <a v-if="link.href != ''" :href="link.href">{{ link.name }}</a>
                                <a v-else href="">{{ link.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PageSlider",
        props: {
            links: {
                type: Array,
            },
        },
    };
</script>

<style scoped></style>
