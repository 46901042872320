import { createApp } from 'vue'
import VueCookies from 'vue-cookies';
import App from './App.vue'

import {createRouter, createWebHistory} from 'vue-router'

import Home from './views/home/index.vue'
import About from './views/about/index.vue'

import Courses from './views/courses/courses.vue'
import Course from './views/courses/course.vue'
import MyCourses from './views/courses/mycourses.vue'
// import MyCourse from './views/courses/mycourse.vue'

import Login from './views/login/index.vue'
import Register from './views/register/index.vue'

import Works from './views/work/works.vue'
// import Work from './views/work/work.vue'

import Partners from './views/partner/partners.vue'
// import Partner from './views/partner/partner.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    
    {
        path: '/courses',
        name: 'Courses',
        component: Courses
    },
    {
        path: '/courses/:id',
        name: 'courses',
        component: Course
    },
    {
        path: '/mycourses',
        name: 'MyCourses',
        component: MyCourses
    },
    // {
    //     path: '/mycourses/:id',
    //     name: 'MyCourse',
    //     component: MyCourse
    // },

    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },

    {
        path: '/work',
        name: 'works',
        component: Works
    },
    // {
    //     path: '/work/:id',
    //     name: 'work',
    //     component: Work
    // },

    {
        path: '/partner',
        name: 'partners',
        component: Partners
    },
    // {
    //     path: '/partner/:id',
    //     name: 'partner',
    //     component: Partner
    // },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

createApp(App).use(router,VueCookies).mount('#app')
