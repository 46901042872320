<template>
    <section class="flat-row bg-theme pd-top-100 wrap-our-teacher">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-sm-6" v-for="partner in partners" :key="partner.id">
                    <div class="flat-teammember">
                        <div class="team-image">
                            <img alt="image" :src="partner.pic" />
                        </div>
                        <div class="profile">
                            <h6 class="name">{{ partner.name }}</h6>
                            <p class="position">
                                {{ partner.description }}
                            </p>
                        </div>
                        <ul class="flat-socials">
                            <li>
                                <a :href="partner.link"><i class="fa fa-globe"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="text-center" v-if="!waiting && partners.length == 0">
                No Data Found
            </div>
            <div v-if="waiting">
                <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="dividers h50"></div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="blog-pagination">
                        <ul class="flat-pagination">
                            <li>
                                <a href="#" @click.prevent="turnPage(currentPage - 1)"><i class="fa fa-long-arrow-left"></i></a>
                            </li>
                            <li v-for="index in Math.ceil(total / counter)" :key="index">
                                <a href="#" @click.prevent="turnPage(index)">{{ index }}</a>
                            </li>
                            <li>
                                <a href="#" @click.prevent="turnPage(currentPage + 1)"><i class="fa fa-long-arrow-right"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "AllPartners",
        props: {
            total: {
                type: Number,
            },
            partners: {
                type: Array,
            },
            counter: {
                type: Number,
            },
            currentPage: {
                type: Number,
            },
        },
        data() {
            return {
                waiting: true
            }
        },
        watch: {
            partners: function (newVal, oldVal) {
                this.waiting = false;
            }
        },
        methods: {
            async turnPage($page) {
                this.$parent.turnPage($page);
            },
        },
    };
</script>
