<template>
    <Header active="login" />
    <section class="flat-row no-pd-top flat-register">
        <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <form @submit="login" id="form-register" class="form-register">
                        <div class="form-register-title">
                            <h3 class="register-title">
                                LOG IN TO<br />
                                <i class="wrap-box ispace7"></i>YOUR ACCOUNT!
                            </h3>
                        </div>
                        <div class="info-register">
                            <p class="wrap-input-name">
                                <input type="text" id="name" v-model="form.username" name="name" placeholder="Your Username" />
                                <small v-if="error.username" class="pull-left text-danger">Username field is required</small>
                            </p>
                            <p class="wrap-input-phone">
                                <input type="password" id="password" v-model="form.password" name="password" placeholder="Password" />
                                <small v-if="error.password" class="pull-left text-danger">Password field is required</small>
                            </p>
                            <br />
                            <br />
                            <div class="wrap-btn">
                                <button type="submit" class="flat-btn">LOG IN</button>
                            </div>
                        </div>
                        <div v-if="waiting">
                            <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                            </div>
                        </div>
                        <div style="padding-bottom: 15px;" v-if="result == 'false'" class="text-center text-danger"><h2>Incorrect Login Information</h2></div>
                    </form>
                </div>
                <div class="col-md-7 no-paddingright">
                    <div class="wrap-register-right wrap-box pdtopleft">
                        <div class="wrap-register-title">
                            <!--<div class="title-top">
                                GET 100S OF INLINE COURSES FOR FREE
                            </div>-->
                            <div class="title-register">
                                LOGIN PAGE
                            </div>
                            <div class="sub-title-register">
                                Enter your account Information!
                            </div>
                        </div>
                        <!--<div id="countdown" class="countdown">
                            <div class="square">
                                <span class="days number"></span>
                            </div>
                            <div class="square">
                                <span class="hours number"></span>
                            </div>
                            <div class="square">
                                <span class="minutes number"></span>
                            </div>
                            <div class="square">
                                <span class="seconds number"></span>
                            </div>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
    import config from "../../helper/config";

    import Header from "../../components/Header.vue";
    import Footer from "../../components/Footer.vue";

    export default {
        name: "Login",
        components: {
            Header,
            Footer,
        },
        data() {
            return {
                waiting: false,
                form: {
                    username: "",
                    password: "",
                },
                result: "",
                error: {
                    username: false,
                    password: false,
                },
            };
        },
        methods: {
            async login(e) {
                e.preventDefault();
                
                this.waiting = true;

                if (this.form.username == "") {
                    this.error.username = true;
                } else {
                    this.error.username = false;
                }
                if (this.form.password == "") {
                    this.error.password = true;
                } else {
                    this.error.password = false;
                }
                if (!this.error.username && !this.error.password) {
                    let res = await config.postData("login", this.form);
                    this.result = res.result;
                    if (this.result != "false") {
                        let d = new Date();
                        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                        let expires = "expires=" + d.toUTCString();

                        $cookies.set("username", this.form.username, "30d");
                        $cookies.set("token", this.result, "30d");

                        window.location.href = "/";
                    }
                }

                this.waiting = false;

            },
        },
    };
</script>

<style></style>
