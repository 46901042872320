<template>
    <section class="flat-row bg-theme pd-top-100 pd-bottom-94 authentication">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="authen-img">
                        <a href="#">
                            <img v-if="site_config" :src="site_config.about_us_f_pic" alt="image" />
                            <img v-if="site_config" class="index2" :src="site_config.about_us_s_pic" alt="image" />
                        </a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="authen-text wrap-box pdtopleft-style2">
                        <div class="authen-title">
                            <div class="title-text-authen">
                                {{ site_config.about_us_title }}
                            </div>
                        </div>
                        <p>{{ site_config.about_us_desc }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Welcome",
        props: {
            site_config: {
                type: Object,
            },
        },
    };
</script>

<style scoped>
.authentication{
    margin: 0px;
    padding: 0px;
}
</style>