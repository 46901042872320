<template>
    <Header active="courses" />

    <PageSlider :links="links" />

    <AllCourses :total="total" :courses="courses" :cats="cats" :counter="counter" :currentPage="currentPage" :isFiltered="isFiltered" :currentCategoryId="currentCategoryId" :selectedCatId="selectedCatId" />

    <Footer />
</template>

<script>
    import config from "../../helper/config";

    import Header from "../../components/Header.vue";
    import Footer from "../../components/Footer.vue";
    import PageSlider from "../../components/PageSlider.vue";

    import AllCourses from "./co/AllCourses.vue";

    export default {
        name: "Courses",
        components: {
            Header,
            Footer,
            PageSlider,

            AllCourses,
        },
        data() {
            return {
                links: [
                    {
                        name: "Home",
                        href: "/",
                    },
                    {
                        name: "Courses",
                        href: "",
                    },
                ],
                total: 0,
                courses: [],
                cats: [],
                counter: 9,
                currentPage: 1,
                isFiltered: false,
                currentCategoryId: 0,
                selectedCatId: "",
            };
        },
        async created() {
            if (this.$route.query.cat) {
                this.selectedCatId = this.$route.query.cat.replace("#", "").split("-")[0];
                if (this.selectedCatId !== "") {
                    this.filterCategory(this.selectedCatId);
                }
            } else {
                this.total = await config.fetchData("course/total");
                this.courses = await config.fetchData("course/page/1/count/" + this.counter);
            }
            this.cats = await config.fetchData("courseCategory");
        },
        methods: {
            async turnPage($page, filtered) {
                if ($page > this.total / this.counter) {
                    return;
                }
                if ($page == 0) {
                    return;
                }
                if (filtered) {
                    this.courses = await config.fetchData("course/category/" + this.currentCategoryId + "/page/" + $page + "/count/" + this.counter);
                    this.currentPage = $page;
                } else {
                    this.courses = await config.fetchData("course/page/" + $page + "/count/" + this.counter);
                    this.currentPage = $page;
                }
            },
            async filterCategory(categoryId) {
                if (categoryId == "") {
                    this.isFiltered = false;
                    this.total = await config.fetchData("course/total");
                    this.courses = await config.fetchData("course/page/1/count/" + this.counter);
                } else {
                    this.isFiltered = true;
                    this.currentCategoryId = categoryId;
                    this.total = await config.fetchData("course/category/" + categoryId + "/total");
                    this.courses = await config.fetchData("course/category/" + categoryId + "/page/1/count/" + this.counter);
                }
            },
        },
    };
</script>

<style></style>
