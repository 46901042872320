<template>
    <section class="flat-row bg-theme pd-top-152 pd-bottom-97 flat-iconbox">
        <Icons :siteServices="siteServices" />
    </section>
    <Popular :popular="popular" />
</template>

<script>
    import Icons from "./Icons.vue";
    import Popular from "./Popular.vue";

    export default {
        name: "Main",
        props: {
            siteServices: {
                type: Array,
            },
            popular: {
                type: Array,
            },
        },
        components: {
            Icons,
            Popular,
        },
    };
</script>

<style scoped></style>
