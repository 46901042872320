<template>
    <Header active="courses" />

    <div v-if="loaded">
        <div v-if="course.length">
            <PageSlider :links="links" />

            <SingleCourse :course="course[0]" />
        </div>
        <div v-else>
            <NotFound />
        </div>
    </div>

    <Footer />
</template>

<script>
    import config from "../../helper/config";

    import Header from "../../components/Header.vue";
    import Footer from "../../components/Footer.vue";
    import PageSlider from "../../components/PageSlider.vue";
    import NotFound from "../../components/NotFound.vue";

    import SingleCourse from "./co/SingleCourse.vue";

    export default {
        name: "Courses",
        components: {
            Header,
            Footer,
            PageSlider,
            NotFound,

            SingleCourse,
        },
        data() {
            return {
                loaded: false,
                links: [
                    {
                        name: "Home",
                        href: "/",
                    },
                    {
                        name: "Courses",
                        href: "/courses",
                    },
                    {
                        name: "",
                        href: "",
                    },
                ],
                course: [],
            };
        },
        async created() {
            let id = this.$route.params.id.split("-", 1);
            let name = this.$route.params.id.replace(id + "-", "").replace("-", " ");

            this.links[this.links.length - 1].name = name;

            this.course = await config.fetchData("course/" + id);
            this.loaded = true;
        },
    };
</script>

<style></style>
