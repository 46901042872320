<template>
    <Header active="partner" />
    <PageSlider :links="links" />
    <Whyus :site_config="site_config" />
    <AllPartners :total="total" :partners="partners" :counter="counter" :currentPage="currentPage" />
    <Footer />
</template>

<script>
    import config from "../../helper/config";

    import Header from "../../components/Header.vue";
    import Footer from "../../components/Footer.vue";
    import PageSlider from "../../components/PageSlider.vue";

    import AllPartners from "./co/AllPartners.vue";
    import Whyus from "../partner/co/Whyus.vue";

    export default {
        name: "Partner",
        components: {
            Header,
            Footer,
            PageSlider,

            AllPartners,
            Whyus,
        },
        data() {
            return {
                site_config: "",
                links: [
                    {
                        name: "Home",
                        href: "/",
                    },
                    {
                        name: "DEMO REEL",
                        href: "",
                    },
                ],
                total: 0,
                partners: [],
                counter: 9,
                currentPage: 1,
            };
        },
        async created() {
            this.site_config = await config.getSiteConfig();
            this.total = await config.fetchData("partner/total");
            this.partners = await config.fetchData("partner/page/1/count/" + this.counter);
        },
        methods: {
            async turnPage($page) {
                if ($page > this.total / this.counter) {
                    return;
                }
                if ($page == 0) {
                    return;
                }
                this.partners = await config.fetchData("partner/page/" + $page + "/count/" + this.counter);
                this.currentPage = $page;
            },
        },
    };
</script>

<style></style>
