<template>
    <section class="flat-row bg-theme pd-top-121 flat-error">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="icon-error">
                        <img src="/assets/images/404.png" alt="image" />
                        <span>404</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="info-error wrap-box pdtop65">
                        <div class="title-section color-title left">
                            <h1 class="title"><span class="color-orange">OPPS!</span> THIS PAGE COULD NOT BE FOUND!</h1>
                            <div class="sub-title">
                                Sorry but the page you are looking for does not exist, have been removed<br />
                                or name changed
                            </div>
                        </div>
                        <div class="wrap-btn">
                            <a class="flat-btn bg-color style3" href="/">GO TO HOMEPAGE</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "NotFound",
    };
</script>

<style scoped></style>
