<template>
    <Header active="home" />
    <Slider :sliders="sliders" />
    <Main :siteServices="siteServices" :popular="popular" />
    <Contactus />
    <Footer />
</template>

<script>
    import config from "../../helper/config";

    import Header from "../../components/Header.vue";
    import Footer from "../../components/Footer.vue";

    import Main from "./co/Main.vue";
    import Slider from "./co/Slider.vue";
    import Contactus from "./co/Contactus.vue";

    export default {
        name: "Home",
        components: {
            Header,
            Footer,

            Main,
            Slider,
            Contactus,
        },
        data() {
            return {
                sliders: [],
                siteServices: [],
                popular: [],
            };
        },
        async created() {
            this.sliders = await config.fetchData("slider");
            this.siteServices = await config.fetchData("siteServices");
            this.popular = await config.fetchData("courseCategory/homePage");
        },
    };
</script>

<style></style>
