<template>
    <Header active="work" />
    <PageSlider :links="links" />
    <AllWorks :total="total" :works="works" :counter="counter" :currentPage="currentPage" />
    <Footer />
</template>

<script>
    import config from "../../helper/config";

    import Header from "../../components/Header.vue";
    import Footer from "../../components/Footer.vue";
    import PageSlider from "../../components/PageSlider.vue";

    import AllWorks from "./co/AllWorks.vue";

    export default {
        name: "Work",
        components: {
            Header,
            Footer,
            PageSlider,

            AllWorks,
        },
        data() {
            return {
                links: [
                    {
                        name: "Home",
                        href: "/",
                    },
                    {
                        name: "Works",
                        href: "",
                    },
                ],
                total: 0,
                works: [],
                counter: 6,
                currentPage: 1,
            };
        },
        async created() {
            this.total = await config.fetchData("work/total");
            this.works = await config.fetchData("work/page/1/count/" + this.counter);
        },
        methods: {
            async turnPage($page) {
                if ($page > this.total / this.counter) {
                    return;
                }
                if ($page == 0) {
                    return;
                }
                this.works = await config.fetchData("work/page/" + $page + "/count/" + this.counter);
                this.currentPage = $page;
            },
        },
    };
</script>

<style></style>
