<template>
    <div class="row">
        <div class="col-md-3 col-sm-12 col-xs-12"></div>
        <div class="col-md-6">
            <vue-plyr>
                <video controls crossorigin playsinline>
                    <source :src="videoPath" />
                </video>
            </vue-plyr>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12"></div>
    </div>
</template>

<script>
    import config from "../../../helper/config";

    import VuePlyr from "vue-plyr";
    import "vue-plyr/dist/vue-plyr.css";
    import { onMounted } from "@vue/runtime-core";

    export default {
        name: "Video",
        components: {
            VuePlyr,
        },
        props: {
            videoPath: {
                type: String,
            },
        },
        data() {
            return {
                secure: {
                    username: $cookies.get("username"),
                    token: $cookies.get("token"),
                },
            };
        },
        methods: {},
    };
</script>
