<template>
    <Header active="about" />
    <PageSlider :links="links" />
    <Welcome :site_config="site_config" />
    <Footer />
</template>

<script>
    import config from "../../helper/config";

    import Header from "../../components/Header.vue";
    import PageSlider from "../../components/PageSlider.vue";
    import Footer from "../../components/Footer.vue";

    import Welcome from "./co/Welcome.vue";

    export default {
        name: "About",
        components: {
            Header,
            PageSlider,
            Footer,

            Welcome,
        },
        data() {
            return {
                site_config: "",
                links: [
                    {
                        name: "Home",
                        href: "/",
                    },
                    {
                        name: "About",
                        href: "",
                    },
                ],
            };
        },
        async created() {
            this.site_config = await config.getSiteConfig();
        },
    };
</script>

<style></style>
