<template>
    <footer id="footer" class="footer style2">
        <div class="container">
            <div class="footer-information">
                <div class="row">
                    <div class="col-md-6 col-sm-6 info-left">
                        <div class="logo-footer">
                            <a href="/"><img v-if="site_config" :src="site_config.icon" alt="image" /></a>
                        </div>
                        <ul class="flat-socials">
                            <li v-if="site_config && site_config.sm_link_fb !== ''">
                                <a :href="site_config.sm_link_fb" target="_blank"><i class="fa fa-facebook" style="color: #4267B2"></i></a>
                            </li>
                            <li v-if="site_config && site_config.sm_link_instagram !== ''">
                                <a :href="site_config.sm_link_instagram" target="_blank"><i class="fa fa-instagram" style="color: #f09433"></i></a>
                            </li>
                            <li v-if="site_config && site_config.sm_link_youtube !== ''">
                                <a :href="site_config.sm_link_youtube" target="_blank"><i class="fa fa-youtube-play" style="color: #FF0000"></i></a>
                            </li>
                            <li v-if="site_config && site_config.sm_link_twitter !== ''">
                                <a :href="site_config.sm_link_twitter" target="_blank"><i class="fa fa-twitter" style="color: #1DA1F2"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-sm-6 info-right">
                        <ul class="flat-information">
                            <li v-if="site_config"><span>M : </span> {{ site_config.mobile }}</li>
                            <li v-if="site_config"><span>E : </span> {{ site_config.email }}</li>
                            <li v-if="site_config"><span>A : </span> {{ site_config.address }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer-widget">
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="widget widget-our-services">
                            <h3 class="widget-title">SITE MAP</h3>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li v-if="site_config && site_config.about_us_active == 1"><a href="/about">About Us</a></li>
                                <li v-if="site_config && site_config.works_active == 1"><a href="/work">Our Works</a></li>
                                <li v-if="site_config && site_config.partners_active == 1"><a href="/partner">DEMO REEL</a></li>
                                <li v-if="site_config && site_config.courses_active == 1"><a href="/courses">Courses</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="widget widget-our-services">
                            <h3 class="widget-title">MY ACCOUNT</h3>
                            <ul>
                                <li v-if="logged_in !== '' && !logged_in && site_config && site_config.login_active == 1"><a href="/login">Login</a></li>
                                <li v-if="logged_in !== '' && !logged_in && site_config && site_config.register_active == 1"><a href="/register">Register</a></li>
                                <li v-if="logged_in !== '' && logged_in"><a href="#" @click="logout">Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div class="bottom style2">
        <div class="container">
            <div class="copyright">
                <p>Copyright © 2021 karisma, Online Learning | Created by <a href="https://www.gaia.sy">Gaia Group</a></p>
            </div>
        </div>
    </div>
    <a class="go-top show"></a>
</template>

<script>
    import config from "../helper/config";

    export default {
        name: "Footer",
        data() {
            return {
                site_config: "",
                logged_in: "",
            };
        },
        async created() {
            this.site_config = await config.getSiteConfig();
            if ($cookies.get("username")) {
                this.logged_in = true;
            } else {
                this.logged_in = false;
            }
        },
        methods: {
            logout() {
                config.logout();
            },
        },
    };
</script>

<style scoped></style>
