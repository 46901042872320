<template>
    <div v-if="waiting">
        <div class="half-circle-spinner">
            <div class="circle circle-1"></div>
            <div class="circle circle-2"></div>
        </div>
    </div>
    <section class="flat-row bg-theme pd-top-100 pd-bottom-no">
        <div class="container">
            <div class="row">
                <div class="col-md-9 col-sm-8 wrap-reponsive">
                    <div class="wrap-course-details">
                        <div class="portfolio">
                            <article class="entry">
                                <div class="entry-post">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="wrap-entry">
                                                <div class="entry-categories">
                                                    <span>{{ course.category_name }}</span>
                                                </div>
                                                <h3 class="entry-title">{{ course.title }}</h3>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="dividers h58"></div>
                                            <div class="entry-number">
                                                <div class="entry-count">TOTAL COURSES:<span class="count"> {{ course.totalCourses }}</span></div>
                                                <div v-if="!isNaN(course.price)" class="entry-price">PRICE:<span class="price"> {{ course.currency + course.price }}</span></div>
                                                <div v-else class="entry-price color-green">PRICE:<span> {{ course.price }}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="featured-post">
                                    <div class="flex-viewport">
                                        <ul class="slides">
                                            <img :src="course.pic" alt="image" />
                                        </ul>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="course-text">
                            <h3 class="course-title-text">
                                INFO COURSES
                            </h3>
                            <p>
                                {{ course.info }}
                            </p>
                        </div>
                        <div class="course-text content2">
                            <h3 class="course-title-text">
                                WHAT YOU WILL LEARN?
                            </h3>
                            <p>
                                {{ course.whatToLearn }}
                            </p>
                        </div>
                    </div>
                    <div class="wrap-course-syliabus">
                        <h3 class="course-title-text title-sylia">
                            COURSES SECTIONS
                        </h3>
                        <div class="flat-table-topic" v-for="video in course.videos" :key="video.title">
                            <h6 class="title-topic">{{ video.title }}</h6>
                            <div class="table-body">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="title-col1">TITLE</th>
                                            <th class="title-col2">TIME</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="info in video.content" :key="info.content">
                                            <td class="icon-play" :class="logged_in && own ? 'play-video' : ''">
                                                <a v-if="logged_in && own" @click.prevent="playVideo(info.id)">{{ info.title }}</a>
                                                <div v-else>{{ info.title }}</div>
                                            </td>
                                            <td>{{ info.time }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-4 wrap-overflow">
                    <div class="sidebar">
                        <div class="widget widget-button">
                            <p class="button-free">{{ course.currency + course.price }}</p>
                            <ul class="infomation-free">
                                <li>Length:<span>{{ course.length }}</span></li>
                            </ul>
                            <div class="wrap-btn" v-if="logged_in && own && loaded">
                                <span class="flat-btn">REGISTERED</span>
                            </div>
                            <div class="wrap-btn" v-else-if="logged_in && !own && requested && loaded">
                                <span class="flat-btn">REQUESTED</span>
                            </div>
                            <div class="wrap-btn" v-else-if="logged_in && !own && !requested && loaded">
                                <a class="flat-btn" href="#" @click.prevent="joinCourse">JOIN NOW</a>
                            </div>
                            <div class="wrap-btn" v-else></div>
                        </div>
                        <div class="widget widget-latest">
                            <h3 class="widget-title">SIMILAR COURSES</h3>
                            <ul>
                                <li v-for="similar in course.similarCourses" :key="similar.id">
                                    <div class="thumb">
                                        <a :href="'/courses/'+similar.link">
                                            <img :src="similar.pic" alt="image" />
                                        </a>
                                    </div>
                                    <div class="text">
                                        <h6>
                                            <a :href="'/courses/'+similar.link">
                                                {{ similar.title }}
                                            </a>
                                        </h6>
                                        <p>PRICE: <span>{{ similar.currency + similar.price }}</span></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Video v-if="playVideoBool && logged_in && own" :videoPath="videoPath" />
    <div v-if="!playVideoBool && waiting">
        <div class="half-circle-spinner">
            <div class="circle circle-1"></div>
            <div class="circle circle-2"></div>
        </div>
    </div>
</template>

<script>
    import config from "../../../helper/config";

    import Video from "./video.vue";

    export default {
        name: "SingleCourse",
        components: {
            Video,
        },
        props: {
            course: {},
        },
        data() {
            return {
                waiting: true,
                secure: {
                    username: $cookies.get("username"),
                    token: $cookies.get("token"),
                },
                loaded: false,
                logged_in: false,
                own: false,
                requested: false,
                courseId: "",
                videoPath: "",
                playVideoBool: false,
                waiting: false,
            };
        },
        watch: {
            course: function (newVal, oldVal) {
                this.waiting = false
            }
        },
        async created() {
            if (this.$route.query.not) {
                await config.fetchDataSecure("notification/" + this.$route.query.not + "/user/" + this.secure.username, this.secure.token);
            }

            let id = this.$route.params.id.split("-", 1)[0];
            this.courseId = id;

            if (this.secure.username != "" && this.secure.username != null) {
                this.logged_in = true;

                let ownCourse = await config.fetchDataSecure("course/" + this.courseId + "/user/" + this.secure.username, this.secure.token);
                if (ownCourse.length > 0) {
                    this.own = true;
                } else {
                    let requstedCourse = await config.fetchDataSecure("request/" + this.courseId + "/user/" + this.secure.username, this.secure.token);
                    if (requstedCourse.result == "true") {
                        this.requested = true;
                    }
                }
                this.loaded = true;
            }
        },
        methods: {
            async joinCourse() {
                if (this.logged_in && !this.own) {
                    let res = await config.postDataSecure("request/user/" + this.secure.username, { username: this.secure.username, courseId: this.courseId }, this.secure.token);
                    if (res.result == "true") {
                        this.requested = true;
                        alert("Request sent successfully");
                    } else {
                        alert(res.result);
                    }
                }
            },
            async playVideo(videoId) {
                if (this.logged_in && this.own) {
                    this.playVideoBool = false;
                    this.waiting = true;

                    await this.timeout(2000);
                    this.videoPath = config.getApiUrl() + "video/" + videoId + "/user/" + this.secure.username + "?token=" + this.secure.token;

                    this.playVideoBool = true;
                    this.waiting = false;
                }
            },
            timeout(ms) {
                return new Promise((resolve) => setTimeout(resolve, ms));
            },
        },
    };
</script>

<style scoped>
    .play-video {
        cursor: pointer;
    }
</style>
