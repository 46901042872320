<template>
    <section class="flat-row" v-if="popular.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="title-section">
                        <h1 class="title">POPULAR COURSE CATEGORIES</h1>
                        <!--<div class="sub-title">
                            Having over 10 million students worldwide and more than 50.000 online courses available.
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div v-for="item in popular" :key="item.id">
                            <a @click="changeVisibleCat(item)" class="popular-tags">
                                <div class="col-md-4 col-sm-6">
                                    <div class="iconbox bg-style" :class="colors[Math.floor(Math.random() * colors.length)]">
                                        <div class="box-header">
                                            <div class="box-icon">
                                                <img :src="item.pic" alt="image" />
                                            </div>
                                            <div class="box-title">
                                                {{ item.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="dividers" v-if="(popular.indexOf(item) + 1) % 3 == 0"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" v-if="visibleCat.length > 0">
                    <div class="about-us wrap-box pdleft" v-for="cat in visibleCat" :key="cat.id">
                        <h2 class="title-about-us">{{ cat.name }}</h2>
                        <div class="text-about-us">
                            <p>
                                {{ cat.description }}
                            </p>
                        </div>
                        <div class="course-about-us">
                            <p>Courses Available:<strong> {{ cat.courseNumbers }}</strong></p>
                        </div>
                        <div class="button-style">
                            <div class="wrap-btn">
                                <a class="flat-btn" :href="'/courses?cat='+cat.id+'-'+cat.name">SEE COURSES</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-if="waiting">
        <div class="half-circle-spinner">
            <div class="circle circle-1"></div>
            <div class="circle circle-2"></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Popular",
        props: {
            popular: {
                type: Array,
            },
        },
        data() {
            return {
                waiting: true,
                colors: ["bg-red", "bg-green", "bg-blue", "bg-cyan", "bg-violet"],
                visibleCat: [{}],
            };
        },
        watch: {
            popular: function (newVal, oldVal) {
                this.visibleCat[0] = newVal[0];
                this.waiting = false;
            },
        },
        methods: {
            changeVisibleCat(data) {
                this.visibleCat[0] = data;
            },
        },
    };
</script>

<style scoped>
    .popular-tags:hover {
        cursor: pointer;
        color: white;
    }

</style>
