<template>
    <section class="flat-row bg-theme pd-top-100 authentication">
        <div class="container">
            <div class="select-category">
                <div class="row">
                    <div class="col-md-7">
                        <div class="showing">
                            <p>Showing {{ courses.length }} of {{ total }} Courses</p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="select-sort">
                            <div class="wrap-select">
                                <select class="select-field all-select" @change="filterCategory($event.target.value)">
                                    <option value="">Select Category</option>
                                    <option v-for="cat in cats" :key="cat.id" :value="cat.id" :selected="selectedCatId !== '' && selectedCatId == cat.id ">{{ cat.name }}</option>
                                </select>
                            </div>
                            <a href="#" class="sort-col active"></a>
                            <a href="#" class="sort-row"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dividers h31"></div>
        <div class="container">
            <div class="portfolio">
                <div class="portfolio-wrap clearfix">
                    <div v-if="waiting">
                        <div class="half-circle-spinner">
                            <div class="circle circle-1"></div>
                            <div class="circle circle-2"></div>
                        </div>
                    </div>
                    <div class="text-center" v-if="courses.length == 0 && !waiting">
                        No Courses Found
                    </div>
                    <div class="item" v-for="course in courses" :key="course.id">
                        <article class="entry">
                            <div class="featured-post">
                                <a :href="'/courses/'+course.link"><img :src="course.pic" alt="image" /></a>
                            </div>
                            <div class="entry-post">
                                <div class="entry-categories">
                                    <span><a href="#">{{ course.category_name }}</a></span>
                                </div>
                                <h3 class="entry-title"><a href="#">{{ course.title }}</a></h3>
                                <div class="entry-number">
                                    <div class="entry-count">TOTAL COURSES:<span class="count"> {{ course.totalVideos }}</span></div>
                                    <div v-if="!isNaN(course.price)" class="entry-price">PRICE:<span class="price"> {{ course.currency + course.price }}</span></div>
                                    <div v-else class="entry-price color-green">PRICE:<span> {{ course.price }}</span></div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
        <div class="dividers h49"></div>

        <div class="container">
            <div class="blog-pagination">
                <ul class="flat-pagination">
                    <li>
                        <a href="#" @click.prevent="isFiltered ? turnPage(currentPage - 1,true) : turnPage(currentPage - 1,false)"><i class="fa fa-long-arrow-left"></i></a>
                    </li>
                    <li v-for="index in Math.ceil(total / counter)" :key="index">
                        <a href="#" @click.prevent="isFiltered ? turnPage(index,true) : turnPage(index,false)">{{ index }}</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="isFiltered ? turnPage(currentPage + 1,true) : turnPage(currentPage + 1,false)"><i class="fa fa-long-arrow-right"></i></a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "AllCourses",
        data() {
            return {
                waiting: true,
            };
        },
        props: {
            total: {
                type: Number,
            },
            courses: {
                type: Array,
            },
            cats: {
                type: Array,
            },
            counter: {
                type: Number,
            },
            currentPage: {
                type: Number,
            },
            isFiltered: {
                type: Boolean,
            },
            currentCategoryId: {
                type: Number,
            },
            selectedCatId: {
                type: String,
            },
        },
        watch: {
            courses: function (newVal, oldVal) {
                this.waiting = false
            },
        },
        methods: {
            async turnPage($page, filtered) {
                this.$parent.clicktest($page, filtered);
            },
            async filterCategory(event) {
                this.$parent.filterCategory(event);
            },
        },
    };
</script>

<style scoped>
</style>
