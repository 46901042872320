<template>
    <div class="container" v-if="siteServices.length > 0">
        <div class="row">
            <div class="col-md-12">
                <div class="title-section">
                    <h1 class="title">
                        Charisma Services
                    </h1>
                    <!--<div class="sub-title">
                        Sign-up today to join over 6 million learners already on ALISON
                    </div>-->
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6" v-for="siteService in siteServices" :key="siteService.id">
                <div class="iconbox style-title" :class="colors[Math.floor(Math.random() * colors.length)]">
                    <div class="box-header">
                        <div class="box-icon">
                            <img :src="siteService.pic" alt="image" />
                        </div>
                        <div class="box-title">
                            <a href="#" title="">{{ siteService.title }}</a>
                        </div>
                    </div>
                    <div class="box-content">
                        <p>
                            {{ siteService.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Icons",
        props: {
            siteServices: {
                type: Array,
            },
        },
        data() {
            return {
                colors: ["icon-red", "icon-green", "icon-blue", "icon-cyan"],
            };
        },
    };
</script>
